export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            id: 'desktop-index',
            name: 'Escritorio',
            to: '/home',
            icon: 'cil-speedometer',
        },
        {
            _name: 'CSidebarNavTitle',
            id: 'mainmnu-index',
            _children: ['Menu Principal']
        },
        {
            _name: 'CSidebarNavDropdown',
            id: "module-permission",
            name: 'Accesos',
            route: '/base',
            icon: 'cil-people',
            items: [{
                    style: "display:none",
                    id: "permission-index",
                    name: 'Permisos',
                    to: '/accesos/permisos'
                },
                {
                    style: "display:none",
                    id: "role-index",
                    name: 'Tipo Usuario',
                    to: '/accesos/tipos'
                },
                {
                    style: "display:none",
                    id: "user-index",
                    name: 'Usuarios',
                    to: '/accesos/usuarios'
                },
                {
                    style: "display:none",
                    id: "permission-index",
                    name: 'Permisos Asignados',
                    to: '/accesos/permisos_asignados'
                }
            ]
        },
        /** mantenimientos */
        {
            _name: 'CSidebarNavDropdown',
            id: "maintenance-module",
            name: 'Mantenimientos',
            route: '/maintenance/supplier',
            icon: 'cil-Pencil',
            items: [{
                    style: "display:none",
                    id: "about-index",
                    name: 'Empresa',
                    to: '/maintenance/about'
                },
                {
                    style: "display:none",
                    id: "supplier-index",
                    name: 'Proveedores',
                    to: '/maintenance/supplier'
                },
                {
                    style: "display:none",
                    id: "supplierexpenses-index",
                    name: 'Provee Gastos',
                    to: '/maintenance/supplierexpenses'
                },
                {
                    style: "display:none",
                    id: "provider-index",
                    name: 'Proveedor natural',
                    to: '/maintenance/provider'
                },
                {
                    style: "display:none",
                    id: "certifications-index",
                    name: 'Certificaciones',
                    to: '/maintenance/certification'
                },
                {
                    style: "display:none",
                    id: "clients-index",
                    name: 'Clientes',
                    to: '/maintenance/clients'
                },
                {
                    style: "display:none",
                    id: "productentries-index",
                    name: 'Entradas de productos',
                    to: '/maintenance/productentries'
                },
                {
                    style: "display:none",
                    id: "transports-index",
                    name: 'Transporte',
                    to: '/maintenance/transport'
                },
                {
                    style: "display:none",
                    id: "destinations-index",
                    name: 'Destino',
                    to: '/maintenance/destination'
                },
                {
                    style: "display:none",
                    id: "box-index",
                    name: 'Cajas',
                    to: '/maintenance/box'
                }
            ]
        },
        /** ESTIMACIONES */
        {
            _name: 'CSidebarNavItem',
            id: "estimate-index",
            name: 'Producción estimada',
            to: '/proccess/estimate',
            icon: 'cil-speedometer',
        },
        /** ACOPIO */
        {
            _name: 'CSidebarNavDropdown',
            id: "process-module",
            name: 'Acopio',
            route: '/proccess/acopio',
            icon: 'cil-ShareBoxed',
            items: [
                // {
                //     style: "display:none",
                //     id: "estimate-index",
                //     name: 'Producción estimada',
                //     to: '/proccess/estimate'
                // },
                {
                    style: "display:none",
                    id: "acopio-index",
                    name: 'Acopio de socio',
                    to: '/proccess/acopio'
                },
                {
                    style: "display:none",
                    id: "acopio-send",
                    name: 'Salida de Acopio',
                    to: '/send/acopio'
                },
                {
                    style: "display:none",
                    id: "provideracopio-index",
                    name: 'Acopio no socio',
                    to: '/proccess/provideracopio'
                }
            ]
        },
        /** VENTAS */
        {
            _name: 'CSidebarNavDropdown',
            id: "module-sale",
            name: 'Emitir documentos',
            route: '/sale',
            icon: 'cil-ShareBoxed',
            items: [
                {
                    style: "display:none",
                    id: "issue-sale",
                    name: 'Ventas',
                    to: '/sale/document'
                },
                {
                    style: "display:none",
                    id: "issue-purchase",
                    name: 'Compras',
                    to: '/purchase/document'
                },
                // {
                //     style: "display:none",
                //     id: "provideracopio-index",
                //     name: 'Acopio no socio',
                //     to: '/sale/provideracopio'
                // }
            ]
        },
        /** CAJA */
        {
            _name: 'CSidebarNavItem',
            id: "caja-module",
            name: 'CAJA',
            to: '/proccess/bead',
            icon: 'cil-speedometer',
        },
        /** Reportes */
        {
            _name: 'CSidebarNavDropdown',
            id: "report-module",
            name: 'Reportes',
            route: '/reports/rptacopio',
            icon: 'cil-ListNumbered',
            items: [
                // {
                //     style: "display:none",
                //     id: "rptacopio-index",
                //     name: 'Acopio',
                //     to: '/reports/rptacopio'
                // },
                {
                    style: "display:none",
                    id: "rptlote-index",
                    name: 'Lote',
                    to: '/reports/rptlote'
                }
            ]
        },
    ]
}]